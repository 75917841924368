@import "_variables.scss";

.mobile-screen {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 60vh;
  width: 100vw;
}

.full-screen {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
}

.siteHeader {
  position: relative;
  .navbar {
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    padding: 1.5rem;
    transition: padding 0.2s;
    @include media-breakpoint-between(xs, lg) {
      padding: 10px;
    }
    @include media-breakpoint-between(lg, xl) {
      padding: 0.8rem;
    }
    .navbar-brand {
      .navBrandLogo {
        width: 273px;
        height: auto;
        transition: width 0.2s;
        @include media-breakpoint-between(sm, xl) {
          width: 200px;
        }
        @include media-breakpoint-between(xs, sm) {
          width: 180px;
        }
      }
    }
    .navbar-nav {
      .nav-link {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        margin-left: 2rem;
        &:hover,
        &:focus,
        &.active {
          color: $colorBlue;
        }
        @include media-breakpoint-between(lg, xl) {
          font-size: 14px;
          margin-left: 1rem;
        }
        &:after {
          display: none;
        }
      }
    }
  }
  .darkHeader {
    padding: 0.5rem 0.5rem;
    transition: padding 0.2s;
    background-color: rgba(0, 0, 0, 0.97);
    .navbar-brand {
      .navBrandLogo {
        width: 200px;
        height: auto;
        transition: width 0.2s;
      }
    }
  }

  .carousel-caption {
    top: 0;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 15vw;
    right: 15vw;
    font-family: $titleFont;
    text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.7);
    h3 {
      margin: 0px 0 0.75rem 0;
      padding: 0px;
      font-size: 2.875rem;
      font-weight: 700;
      color: #ffa400;
    }
    p {
      padding: 0px;
      margin: 0px;
      font-size: 1.625rem;
      font-weight: 700;
    }
    @include media-breakpoint-between(xs, sm) {
      left: 5vw;
      right: 5vw;
      h3 {
        font-size: 1.6rem;
      }
      p {
        font-size: 1rem;
      }
    }
    @include media-breakpoint-between(sm, lg) {
      left: 5vw;
      right: 5vw;
      h3 {
        font-size: 2.6rem;
      }
      p {
        font-size: 1.6rem;
      }
    }
    @include media-breakpoint-between(lg, xl) {
      left: 10vw;
      right: 10vw;
      h3 {
        font-size: 2rem;
      }
      p {
        font-size: 1.2rem;
      }
    }
    @include media-breakpoint-between(xl, xxl) {
      left: 2vw;
      right: 2vw;
      h3 {
        font-size: 2rem;
      }
      p {
        font-size: 1.5rem;
      }
    }
  }

  .certification {
    @include media-breakpoint-between(xs, xl) {
      display: none;
    }
    @include media-breakpoint-between(xl, xxl) {
      min-height: 110px;
      &::before {
        margin-top: 443px !important;
      }
    }
    background: #f2f2f2;
    position: absolute;
    bottom: 0px;
    width: 100vw;
    right: 0px;
    min-height: 136px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    justify-content: center;
    img {
      margin: 0 20px;
    }
  }
}

@include media-breakpoint-between(xs, lg) {
  .siteHeader {
    .navbar-collapse {
      background: #fff;
      position: absolute;
      top: 82px;
      left: 0px;
      right: 0;
      padding: 10px 0px;
      .navbar-nav {
        .nav-link {
          color: #fff;
          font-size: 16px;
          font-weight: 400;
          margin-left: 1rem;
          &:hover,
          &:focus,
          &.active {
            color: #fff;
          }
          @include media-breakpoint-between(lg, xl) {
            font-size: 14px;
            margin-left: 1rem;
          }
          &:after {
            display: none;
          }
        }
      }
    }
  }
}

.jContent {
  justify-content: center;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0rem;
    padding-left: 0rem;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    left: 39px !important;
    top: 45px;
    border: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 5px 0px;
    transition: all 300ms linear;
    border-top: solid 10px;
    border-image-source: linear-gradient(90deg, #4691c4, #5a8c39);
    border-image-slice: 1;
    min-width: 260px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    .dropdown-item {
      font-size: 15px;
      &:hover {
        background: $colorBlue;
        color: #fff;
      }
    }
  }
}

.AboutCertification {
  background: #fff;
  padding: 30px 0px;
  text-align: center;
  h1 {
    font-size: 15px;
  }
}
