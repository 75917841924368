﻿@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;600;700;800&family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;700&display=swap");

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

$siteFont: "Roboto", sans-serif;
$siteFontPopins: "Poppins", sans-serif;
$titleFont: "Raleway", sans-serif;
$siteColor: #00539a;
$colorBlue: #4691c4;
$subTextClor: #404040;
$colorGreen: #618c39;

$small-mobile: "only screen and (min-width: 320px) and (max-width: 575.98px)";
$medium_mobile: "only screen and (min-width: 576px) and (max-width: 767.98px)";
$tablets: "only screen and (min-width: 768px) and (max-width: 991.98px)";
$tablets_between: "only screen and (min-width: 992px) and (max-width: 1024px)";
$medium_desktops: "only screen and (min-width: 992px) and (max-width: 1600px)";
$large_desktops: "only screen and (min-width: 1601px) and (max-width: 3000px)";
