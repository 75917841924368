@import "_variables.scss";

h1.title {
  font-family: $siteFontPopins;
  color: $siteColor;
  font-size: 2.125rem;
  margin: 0 0 1rem 0;
  text-transform: uppercase;
  padding: 0;
  font-weight: 700;
  @include media-breakpoint-between(lg, xxl) {
    font-size: 1.75rem;
  }
  @include media-breakpoint-between(xs, md) {
    font-size: 1.5rem;
  }
}
p.subText {
  font-family: $siteFontPopins;
  color: $subTextClor;
  font-size: 0.9rem;
  margin: 0 0 0.75rem 0;
  padding: 0;
  line-height: 22px;
}

a.siteLink {
  color: $siteColor;
  font-family: $siteFontPopins;
  font-size: 0.9rem;
  font-weight: 500;
  text-decoration: none;
  &:hover {
    color: #333;
  }
}

/*Digital Transformation*/
.hComponent {
  background: #fff;
  padding: 6.25rem 0px;
  position: relative;
  z-index: 9;
  &.bgGray {
    background: #f2f2f2;
  }
  @include media-breakpoint-between(xs, lg) {
    padding: 1.5rem;
  }
  @include media-breakpoint-between(lg, xxl) {
    padding: 2rem;
  }
  .hComponent_container {
    width: 95vw;
    margin: 0px auto;
    display: flex;
    flex-direction: row;
    align-items: top;
    @include media-breakpoint-between(xs, lg) {
      width: 90vw;
      flex-direction: column;
    }
    @include media-breakpoint-between(lg, xxl) {
      width: 95vw;
    }
    .hComponent_Left {
      width: 25%;
      margin-right: 3rem;
      padding-top: 30px;
      @include media-breakpoint-between(xs, md) {
        width: inherit;
        flex: 1;
        margin: 1rem 0;
        padding: 0px;
        text-align: center;
      }
      @include media-breakpoint-between(md, lg) {
        width: inherit;
        margin: 1rem 0;
        padding: 0px;
        text-align: center;
      }
    }
    .hComponent_Right {
      flex: 1;
      .hComponentList {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @include media-breakpoint-between(xs, md) {
          flex-direction: column;
        }
        .hComponentListItem {
          width: 33.33%;
          padding: 20px 30px;
          border: solid 1px #f2f2f2;
          margin-left: -1px;
          margin-bottom: -1px;
          img {
            margin-bottom: 20px;
          }
          h2 {
            font-size: 1.125rem;
            font-family: $siteFontPopins;
            line-height: 24px;
            margin: 0px 0 0.75rem 0;
          }
          @include media-breakpoint-between(md, xl) {
            width: 50%;
          }
          @include media-breakpoint-between(xs, md) {
            width: inherit;
            flex: 1;
          }
        }
      }
    }
  }
}

.iCard {
  background: $siteColor;
  border: none;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  font-family: $siteFontPopins;
  @include media-breakpoint-between(xs, lg) {
    margin-bottom: 20px;
  }
  .card-img-top {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .card-body {
    color: #fff;
    padding: 0px;
    margin-top: -25px;
    min-height: 320px;
    @include media-breakpoint-between(xs, xl) {
      min-height: auto;
    }
    .card-title {
      background: #fff;
      color: #333;
      width: 90%;
      padding: 15px;
      font-weight: 600;
      font-size: 1.125rem;
    }
    .card-text {
      padding: 15px;
      font-size: 1rem;
      line-height: 23px;
    }
  }
}

.DTrans {
  padding: 100px 0px;
  .DTransHeader {
    text-align: center;
    margin-bottom: 50px;
  }
  p {
    font-family: $siteFontPopins;
    color: $subTextClor;
    font-size: 1rem;
    margin: 0 0 0.75rem 0;
    padding: 0;
    line-height: 22px;
  }
  .DTransListItem {
    padding: 25px;
    border: solid 1px #f2f2f2;
    min-height: 300px;
    margin-bottom: 25px;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
        rgba(0, 0, 0, 0.05) 0px 5px 10px;
    }
    img {
      margin-bottom: 20px;
    }
    h2 {
      font-size: 1.125rem;
      font-family: $siteFontPopins;
      line-height: 24px;
      margin: 0px 0 0.75rem 0;
    }
    a {
      background: red;
    }
  }
}
